import Model from '@vuex-orm/core/dist/src/model/Model';
import ApiORMRelationsQueryBuilder from '@/core/bridge/orm/api/relations/ApiORMRelationsQueryBuilder';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;

export default class ORMEntity {
  public model: InstanceOf<Model> | null = null;
  private readonly itemsId: number | string | null = null;
  private readonly relations: ApiORMRelationsQueryBuilder | null = null;
  private readonly injection: boolean = false;

  /**
   * @param itemsId
   * @param model
   * @param relations
   */
  public constructor(
    itemsId: number | string | null = null,
    model: InstanceOf<Model> | null = null,
    relations: ApiORMRelationsQueryBuilder | null = null,
  ) {
    if (itemsId !== null) {
      this.itemsId = itemsId;
      this.injection = true;
    }
    this.relations = relations;
    this.model = model;
  }

  /**
   * Get items from store
   */
  public get item(): InstanceOf<Model> | null {
    if (!this.model || !this.itemsId) {
      return null;
    }
    // new orm query
    const query = this.model.query();

    // add relations to query
    if (!!this.relations && !this.relations.empty()) {
      this.relations.buildORMQueryRelations(query, this.model);
    }

    return query.find(this.itemsId);
  }

  public get id(): number | string | null {
    return this.itemsId;
  }

  public get isInjected() {
    return this.injection;
  }
}
